<template>
    <div class="operative-furniture">
        <store-product 
            :data_products="data_products">
        </store-product>
        <!-- Footer -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
	metaInfo: {
		title: 'Muebles Operativos',
		meta: [
			// Ummami Express
			{ name: 'description', content:'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{ name: 'keywords', content:'industrias pico, muebles oficina, muebles oficina Bucaramanga, muebles para oficina Bucaramanga, estantería metálica, estanterías metálicas, fabricas de estanterías metálicas, divisiones para oficina Bucaramanga, escritorios gerenciales Bucaramanga, escritorios para gerencia, Archivadores metálicos, fabrica de muebles para oficina, sillas para oficinas Bucaramanga, diseño de oficinas Bucaramanga, muebles para recepción Bucaramanga' },
			{ name: 'author', content:'Angecia de Marketing Digital Winketing | Sebastian Báez - Leader Programming Winketing' },
			// SOCIAL
			// OpenGraph data (Most widely used)
			{property: 'og:title', content: 'Muebles Operativos'},
			{property: 'og:site_name', content: 'Industrias Pico'},
			{property: 'og:type', content: 'website'},
			{property: 'og:url', content: 'https://industriaspico.com'},
			{property: 'og:image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			{property: 'og:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.'},
			// Twitter card    
			{name: 'twitter:card', content: 'summary'},
			{name: 'twitter:site', content: 'https://industriaspico.com'},
			{name: 'twitter:title', content: 'Muebles Operativos'},
			{name: 'twitter:description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			// Your twitter handle, if you have one.
			// {name: 'twitter:creator', content: '@alligatorio'},
			{name: 'twitter:image:src', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'},
			// Google / Schema.org markup:
			{itemprop: 'name', content: 'Muebles Operativos'},
			{itemprop: 'description', content: 'Fabrica de muebles para oficinas y estanterías metálicas, escritorios para oficinas, sillas para oficinas, divisiones para oficinas, estanteria metalica, bucaramanga, más de 30 años de experiencia.' },
			{itemprop: 'image', content: 'https://industriaspico.com/public_sb_images/logo-industrias-pico.png'}
		]
    },
    data() {
        return {
            data_products: {
                title_page: 'Muebles para secretarias y equipos de trabajo',
                types: [
                    { text: 'Todos', value: 'todos' }
                ],
                products: [
                    {
                        name: 'Mueble operativo 1',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/operativos/productos/operativo_1.jpg',
                        slides: [
                            'mobiliario-de-oficina/operativos/productos/operativo_1.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Mueble operativo 2',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/operativos/productos/operativo_2.jpg',
                        slides: [
                            'mobiliario-de-oficina/operativos/productos/operativo_2.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Mueble operativo 3',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/operativos/productos/operativo_3.jpg',
                        slides: [
                            'mobiliario-de-oficina/operativos/productos/operativo_3.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    },
                    {
                        name: 'Mueble operativo 4',
                        description: ``,
                        url_image: 'mobiliario-de-oficina/operativos/productos/operativo_4.jpg',
                        slides: [
                            'mobiliario-de-oficina/operativos/productos/operativo_4.jpg'
                        ],
                        price: '',
                        tags: ['todos']
                    }
                ],
            }
        }
    }
}
</script>